import React from 'react'

import Layout from '../components/layout';
import SEO from '../components/seo';

const Freshers = () => {
    return (
        <Layout>
            <SEO title="Freshers" />
            hello form freshers
        </Layout>
    )
}

export default Freshers
